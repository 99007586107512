import commonHelper from '@/app/utility/common.helper.utility';
import appStrings from '@/app/utility/string.utility';
import DatePicker from 'vue2-datepicker';
import uploadProvisionFile from './uploadProvisionFile';
import legalEntityListVset from '../../codAdmin/legalEntityListVset/';


export default {
  name: 'uploadProvisions',
  components: {
    appStrings,
    DatePicker,
    uploadProvisionFile,
    legalEntityListVset
  },
  watch: {
    currentPage: function() {
      this.getFaLocation();
    },
    perPage: function() {
      this.currentPage = 1;
      this.getFaLocation();
    }
  },
  data() {
    return {
      totalRows: null,
      currentPage: 1,
      loader: false,
      perPage: commonHelper.perPageRecord,
      pageOptions: commonHelper.getPageOptionSelectedInvoice(),
      editMode: false,
      responseMsg: '',
      isSuccess: false,
      uploadProvData: [],
      uploadProvFields: [
        {
          key: 'legal_entity'
        },
        {
          key: 'period'
        },
        {
          key: 'uploaded_by'
        },
        {
          key: 'status'
        },
        {
          key: 'view_details'
        },
        {
          key: 'debit'
        },
        {
          key: 'credit'
        },
        {
          key: 'file_name'
        }
      ],
      showAlert: false,
      showValueSetModal: false,
      parent_value_set_id: null,
      uploadedBy: {
        text: null,
        value: null
      },
      period: null,
      uploadProvStatus: {
        text: null,
        value: null
      },
      legalEntity: {
        text: null,
        value: null
      },
      defaultValue:{
        value: null,
        text: null
      },
      showUploadBatchDetailsModal:false,
      showLegalEntityModal:false,
      openModalType:'uploadProv'
    };
  },
  mounted() {
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'add' && !this.showOpenModal) {
          this.showHideAddFaLocationModal(true);
        } else if (actionName === 'update' || actionName === 'edit') {
          this.editMode = true;
          this.showHideUploadBatchDetailsModal(true)
        }
      }
    });
  },
  methods: {
    mainSearch() {},
    clear() {},
    showHideUploadBatchDetailsModal(flag){
        this.showUploadBatchDetailsModal=flag
    },
    showHideLegalEntityModal(flag) {
      this.showLegalEntityModal = flag;
    },
    openValueSetModal(vsetCode) {
      this.vsetCode = vsetCode;
      this.showValueSetModal = true;
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },

    selectedvalueSet(item) {
         if (this.vsetCode === appStrings.VALUESETTYPE.UPLOAD_PROVISION_STATUS_VSET) {
            this.uploadProvStatus = {
              value: item.value_set_dtl_id,
              text: item.value_code
            };
          }
        this.showValueSetModal = false;
      },

    closeValueSetModal() {
      this.showValueSetModal = false;
    },
    clearVsetValues(vsetCode) {
        switch(vsetCode){
          case appStrings.VALUESETTYPE.LEGAL_ENTITY_LIST:
            this.legalEntity = this.defaultValue
            break;
          case appStrings.VALUESETTYPE.UPLOAD_PROVISION_STATUS_VSET:
                this.uploadProvStatus = this.defaultValue
                break;
        }
      },
      mapSelectedVsetData(item) {
        if (item.name === 'uploadProvisions') {
          this.legalEntity = {
            text: item.le_name,
            value: item.le_id
          };
          this.showLegalEntityModal = false;
        } 
      },
  },
  beforeDestroy() {
    this.unsubscribe();
  }
};
